import { AiFillMessage } from 'react-icons/ai';
import { SlScreenSmartphone } from 'react-icons/sl';
import { MdVideoCall } from 'react-icons/md';
import { FaFax } from 'react-icons/fa';
import { BsFillGeoAltFill } from 'react-icons/bs';
import './Features.css';

const features = [
  {
    title: 'Messaging',
    description:
      'The messaging feature allows for quick and easy communication between providers and facilities.',
    link: 'http://www.doc-nock.com/login',

    linkText: 'Read more',
    Icon: <AiFillMessage className={'feature-icon'} />,
  },
  {
    title: 'Audio Calls',
    description:
      'Our platfrom offers high-quality autio calls, allowing for clear and effective communication between providers and facilities.',
    link: 'http://www.doc-nock.com/login',

    linkText: 'Read more',
    Icon: <SlScreenSmartphone className={'feature-icon'} />,
  },
  {
    title: 'Video Calls',
    description:
      "This feature allows for face-to-face communication between providers and facilities, even when they can't be in the same room.",
    link: 'http://www.doc-nock.com/login',

    linkText: 'Read more',
    Icon: <MdVideoCall className={'feature-icon'} />,
  },
  {
    title: 'eFax',
    description:
      'State of the art built-in-fax feature allows you to send and receive faxes directly within our platform, eliminating the need for separate fax machines and reducing the risk of errors.',
    link: 'http://www.doc-nock.com/login',

    linkText: 'Read more',
    Icon: <FaFax className={'feature-icon'} />,
  },
  {
    title: 'Geofencing',
    description:
      'This feature allows you to set up virtual boundary and can be turned on or off based on requirement.',
    link: 'http://www.doc-nock.com/login',

    linkText: 'Read more',
    Icon: <BsFillGeoAltFill className={'feature-icon'} />,
  },
];

const Features = () => {
  return (
    <>
      <div className='Features section-margin serviceWrapper' id='features'>
        <h1 className={'heading blue-text'}>Features</h1>
        <div className='Features__Container'>
          <p className='primary-text text-lg'>
            The following features would benefit any healthcare company working
            with nursing homes and assisted living settings and need a HIPPA
            compliant communication platform. Instant provider feedback and
            intervention will help improve outcomes and patient/family
            satisfaction.
          </p>
          <div className='Features__Cards'>
            {features.map((feature, index) => (
              <div
                className={`Features__Card hover-div ${
                  index % 2 === 0 ? 'green-bg' : 'blue-bg'
                }`}
                key={index}
              >
                <p
                  className={` ${index % 2 === 0 ? 'blue-text' : 'green-text'}`}
                >
                  {feature.Icon}
                </p>
                <h3
                  className={`Features__CardTitle ${
                    index % 2 === 0 ? 'blue-text' : 'green-text'
                  }`}
                >
                  <h1>{feature.title}</h1>
                </h3>
                <div
                  className={`hidden-content ${
                    index % 2 === 0 ? 'blue-text' : 'blue-text'
                  }`}
                >
                  <p className={` ${'blue-text'}`}>{feature.Icon}</p>
                  {feature.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Features;
