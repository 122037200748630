import React from 'react';
import './Screenshots.css';

const Screenshots = () => {
  return (
    <div className='wrapper-container section-margin' id='gallery'>
      <h1 className='heading blue-text'>Screenshots</h1>
      <h3 className='mt-5 text-left w-full blue-text text-lg ml-4'>App</h3>
      <div className={'row'}>
        <div className={'column'}>
          <img
            src='/screenshots/mobile1.png'
            alt='screenshot doc nock'
            style={{ width: '100%' }}
          />
        </div>
        <div className='column'>
          <img
            src='/screenshots/mobile3.png'
            alt='screenshot doc nock'
            style={{ width: '100%' }}
          />
        </div>
        <div className='column'>
          <img
            src='/screenshots/mobile2.png'
            alt='screenshot doc nock'
            style={{ width: '100%' }}
          />
        </div>
        <div className='column'>
          <img
            src='/screenshots/mobile4.png'
            alt='screenshot doc nock'
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <h3 className='mt-5 text-left w-full blue-text text-lg ml-4'>Browser</h3>
      <div className='desktop-row'>
        <img
          src='/screenshots/screen2.png'
          alt='screenshot doc nock'
          style={{ width: '100%' }}
        />
        <img
          src='/screenshots/screen1.png'
          alt='screenshot doc nock'
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default Screenshots;
