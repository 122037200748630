import React from 'react';
import styles from './Hero.module.css';
import '../Features/Features.css';
const Hero = () => {
  return (
    <div className={`section-margin ${styles.container}`}>
      <h1
        className={`blue-text heading text-center  w-[80%]  min-w-full main-heading capitalize`}
      >
        Interconnected Teams: Centralized Communication For PALTC, Assisted
        Living, Home Health and Hospice teams.
      </h1>
      <div className={styles.heroImage}>
        <div className={` ${styles.leftImage}`}>
          <h1 className='text-4xl blue-text'>Benefits for facilities</h1>
          <div className={`${styles.hoverDiv} ${styles.imgContainer}`}>
            <img src='/hero/nurses.png' alt='Nurses' />
            <div className={`${styles.hiddenContent} ${'blue-text'}`}>
              <p className={` ${'blue-text'}  text-xl text-left p-5`}>
                {[
                  'Ease of use',
                  'Save nursing time',
                  'Timely intervention ',
                  'Prevent hospitalization ',
                  'Patient/Family Satisfaction ',
                ].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </p>
            </div>
          </div>
        </div>
        <img src='/hero/banner2.png' alt='' />
        <div className={styles.rightImage}>
          <h1 className='text-4xl blue-text'>Benefits for providers</h1>
          <div className={`${styles.hoverDiv} ${styles.imgContainer}`}>
            <img src='/hero/doctors.jpeg' alt='Doctors' />
            <div className={`${styles.hiddenContent} ${'blue-text'}`}>
              <p className={` ${'blue-text'}  text-xl text-left p-5`}>
                {[
                  'Timely interventions',
                  'Avoid non-urgent calls',
                  'Decrease liability ',
                  'Efficient use of time',
                  'Stay informed ',
                ].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
