import React from 'react';
import styles from './About.module.css';

const About = () => {
  return (
    <div className='wrapper-container section-margin' id='about'>
      <div
        className={styles.aboutContainer}
        style={{ display: 'flex', alignItems: 'flex-start' }}
      >
        {/* <p className='green-text text-lg  text-left underline'></p> */}
        <h2 className=' text-3xl  blue-text'>About Us</h2>
        <div className={`mt-4 ${styles.introductionContainer}`}>
          <p
            className={`primary-text text-justify ${styles.introduction} p-5 text-lg`}
          >
            DockNock is an innovative healthcare platform that allows secure and
            efficient communication amongst healthcare professionals and nursing
            home personnel. It uses actual feedback from post-acute long term
            care healthcare professionals and administrators to streamline care
            coordination and deliver improved clinical outcomes.
          </p>
          {/* <img src='/doctor.avif' alt='doctor' /> */}
        </div>
        <h2 className='blue-text mt-10 text-3xl w-full text-left '>
          Mitul Dave,{' '}
          <span className='blue-text text-lg  text-left '>MD, CMD, WCC</span>
        </h2>
        <div
          className={`mt-4 ${styles.introductionContainer}`}
          style={{ flexDirection: 'column' }}
        >
          <p
            className={`primary-text text-justify ${styles.introduction} p-5 text-lg`}
          >
            Dr. Mitul Dave is an expert in the field of post-acute long-term
            care, having dedicated over 20 years to provide excellent care. He
            completed his residency at the University of Maryland Hospital,
            where he served as chief resident in internal medicine. He is
            currently a certified medical director at a number of sub-acute
            facilities and nursing homes in the Baltimore area. His extensive
            experience make him an ideal leader to provide valuable insights and
            feedback on the development and implementation of DockNock.
          </p>
          {/* <img src='/doctor.avif' alt='doctor' /> */}
        </div>

        {/* <div className={`${styles.introOfMitul} section-margin`}>
          <div className={styles.column}>
            <div className={styles.container}>
              <h2 className='blue-text text-3xl text-center'>Mitul Dave</h2>
              <p className='green-text text-lg  text-center'>MD, CMD, WCC</p>
              <p
                className='text-center primary-text mt-2'
                style={{ fontWeight: 500 }}
              >
                Dr. Dave trained at the university of Maryland midtown campus
                and worked in the Emergency room for many years. He continues to
                be instrumental in sub-acute units and is a medical director in
                various Nursing homes. He is also the Co-Founder of Juniper
                Healthcare whose presence is in many nursing homes and assisted
                livings.
              </p>
              <p className='text-center blue-text-hover my-2 text-lg'>
                <a href='mailto:MD@doc-nock.com'>MD@doc-nock.com</a>
              </p>
            </div>
          </div>
        </div> */}

        {/* <div className={`${styles.introOfMitul} section-margin`}>
          <div className={styles.column}>
            <div className={styles.container}>
              <h2 className='blue-text text-3xl text-center'>Mitul Dave</h2>
              <p className='green-text text-lg  text-center'>MD, CMD, WCC</p>
              <p
                className='text-center primary-text mt-2'
                style={{ fontWeight: 500 }}
              >
                Dr. Dave trained at the university of Maryland midtown campus
                and worked in the Emergency room for many years. He continues to
                be instrumental in sub-acute units and is a medical director in
                various Nursing homes. He is also the Co-Founder of Juniper
                Healthcare whose presence is in many nursing homes and assisted
                livings.
              </p>
              <p className='text-center blue-text-hover my-2 text-lg'>
                <a href='mailto:MD@doc-nock.com'>MD@doc-nock.com</a>
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div className={styles.featureContainer}>
        <div className={styles.header}></div>

        {/* <div className={styles.features}>
          {features.map((item, index) => (
            <div className={styles.feature} key={index}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

const features = [
  {
    title: 'HIPPA Compliant',
    description: `Our platform is fully HIPPA compliant, ensuring the highest level of security and privacy for your communication.`,
  },
  {
    title: 'Messaging',
    description: `Our messaging feature allows for quick and easy communication between providers, patients, and their families.`,
  },
  {
    title: 'Audio Calls',
    description: `Our platform offers high-quality audio calls, allowing for clear and effective communication between providers and patients.`,
  },
  {
    title: 'Video Calls',
    description: `Our video call feature allows for face-to-face communication between providers and patients, even when they can't be in the same room.`,
  },
  {
    title: 'Built-inFax',
    description: `Our built-in fax feature allows you to send and receive faxes directly within our platform, eliminating the need for separate fax machines and reducing the risk of errors.`,
  },
  {
    title: 'Chat Groups',
    description: `Our chat groups feature allows you to create custom groups for specific patient cases or teams, making it easy to communicate and collaborate with the right people.`,
  },
  {
    title: 'Geofencing',
    description: `Our geofencing feature allows you to set up virtual perimeters around specific locations, such as nursing homes or assisted living facilities, and receive alerts when a patient or provider enters or leaves that location.`,
  },
];

export default About;
