import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Hero from './Components/Hero/Hero';
import Footer from './Components/Footer/Footer';
import Services from './Components/Services/services';

import './App.css';

import Testimonial from './Components/Testimonial/Testimonial';
import './App.css';
import Features from './Components/Features/Features';
import Screenshots from './Components/Screenshots/Screenshots';
import AnimatedVideo from './Components/AnimatedVideo/AnimatedVideo';
import About from './Components/About/About';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';

function App() {
  return (
    <>
      <div className='MainContainer' id='home'>
        <Navbar />

        <div className='Container'>
          <Hero />
          <Features />
          <AnimatedVideo />
          {/* <Services /> */}
          <Screenshots />
          {/* <Testimonial /> */}
          <About />
          <PrivacyPolicy/>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default App;
