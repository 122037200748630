import React, { useState, useEffect } from 'react';
import styles from './NavBar.module.css';
import MobileSlideInBar from './MobileSlideInBar/MobileSlideInBar';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

const NavBar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const reset = () => setShowSidebar(() => false);
  const set = () => setShowSidebar(() => true);
  console.log(showSidebar);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', reset);
    }
    return () => {
      window.removeEventListener('resize', reset);
    };
  }, []);
  return (
    <>
      <nav
        className={styles.NavWrapper}
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 100,
        }}
      >
        <div className={styles.MainDiv}>
          <div className={styles.Inter}>
            <div
              className={styles.logoSection}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              {/* <img
                src='/logo.png'
                alt='doc nock '
                width={100}
                // height={59}
              /> */}
              <h1 className='blue-text heading mr-10 '>
                Doc<span className='green-text'>Nock</span>
              </h1>
            </div>
            <div className={styles.ItemsSection}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                {navContent.map((item, index) => (
                  <p key={index} className='blue-text-hover'>
                    <a href={`#${item.id}`}>{item.name}</a>
                  </p>
                ))}
              </div>
              <button className={` green-bg-button skew-button`}>
                <a href='https://admin.doc-nock.com/'>LOGIN</a>
              </button>
            </div>

            <div className={styles.onmobileonly}>
              <div
                className={`${styles.CancelOrOpen}`}
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <p className='primary-text ' style={{ zIndex: 111 }}>
                  {showSidebar ? (
                    <FaTimes className={`green-text ${styles.icon}`} />
                  ) : (
                    <AiOutlineMenu className={`green-text ${styles.icon}`} />
                  )}
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className={styles.NavBarmbl}>
          {
            <MobileSlideInBar
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
          }
        </div>
      </nav>
    </>
  );
};
export const navContent = [
  {
    name: 'HOME',
    id: 'home',
  },
  {
    name: 'FEATURES',
    id: 'features',
  },
  {
    name: 'GALLERY',
    id: 'gallery',
  },
  {
    name: 'ABOUT US',
    id: 'about',
  },
  {
    name: 'PRIVACY POLICY',
    id: 'privacy',
  },
];
export default NavBar;
