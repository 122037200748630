import React from 'react';
import { navContent } from '../Navbar';
import styles from './MobileSlideInBar.module.css';

const MobileSlideInBar = ({ showSidebar, setShowSidebar }) => {
  return (
    <div
      className={`${styles.SidebarContainer}  ${
        showSidebar ? styles.showSidebar : styles.hideSidebar
      }`}
    >
      <div
        className={` ${styles.navLinks}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {navContent.map((item, index) => (
          <p key={index} className='primary-text green-text'>
            <a
              style={{ textDecoration: 'none' }}
              className='primary-text green-text-hover'
              href={`#${item.id}`}
              onClick={() => setShowSidebar(false)}
            >
              {item.name}
            </a>
          </p>
        ))}

        <button className={` green-bg-button skew-button`}>
          <a href='http://www.doc-nock.com/login'>LOGIN</a>
        </button>
      </div>
    </div>
  );
};

export default MobileSlideInBar;
