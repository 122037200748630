import React from "react";
import styles from "./PrivacyPolicy.module.css";



const PrivacyPolicy = () => {

    const handleDownload = () => {

try{
    const fileUrl = "DocNock_Privacy_policy.pdf"; // URL of the file to download
const fileName = 'DocNock_Privacy_policy.pdf'; // name of the file to save as

// Create an invisible anchor tag
const link = document.createElement('a');
link.style.display = 'none';
link.href = fileUrl;
link.setAttribute('download', fileName);

// Add the anchor tag to the document body and click it
document.body.appendChild(link);
link.click();

// Clean up the anchor tag
document.body.removeChild(link);

}catch(err){
    console.log(`err`, err);

}

        
        
      };


  return (
    <div className="wrapper-container section-margin" id="privacy">
      <div
        className={styles.privacyPolicyContainer}
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        {/* <p className='green-text text-lg  text-left underline'></p> */}
        <h2 className=" text-3xl  blue-text">Privacy Policy</h2>
        <div className={`mt-4 ${styles.introductionContainer}`}>
          <p
            className={`primary-text text-justify ${styles.introduction} p-5 text-lg`}
          >
            This privacy notice for DocNock LLC ("Company," "we," "us," or
            "our"), describes how and why we might collect, store, use, and/or
            share ("process") your information when you use our services
            ("Services"), such as when you: Visit our website at{" "}
            <a className="blue-text" href="http://www.doc-nock.com">
              http://www.doc-nock.com
            </a>
            , or any website of ours that links to this privacy notice Download
            and use our mobile application (DocNock), or any other application
            of ours that links to this privacy notice Engage with us in other
            related ways, including any sales, marketing, or events Questions or
            concerns? Reading this privacy notice will help you understand your
            privacy rights and choices. If you do not agree with our policies
            and practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at{" "}
            <a href="mailto:MD@doc-nock.com" className="green-text-hover">
              MD@doc-nock.com
            </a>
            <div style={{paddingTop:"20px"}}>Note - For more details <button className="blue-text" onClick={handleDownload}>
click here
</button> to download the privacy policy </div>
          </p>

          {/* <img src='/doctor.avif' alt='doctor' /> */}
        </div>
      </div>
      <div className={styles.featureContainer}>
        <div className={styles.header}></div>

        {/* <div className={styles.features}>
      {features.map((item, index) => (
        <div className={styles.feature} key={index}>
          <h3>{item.title}</h3>
          <p>{item.description}</p>
        </div>
      ))}
    </div> */}
      </div>
    </div>
  );
};

const features = [
  {
    title: "HIPPA Compliant",
    description: `Our platform is fully HIPPA compliant, ensuring the highest level of security and privacy for your communication.`,
  },
  {
    title: "Messaging",
    description: `Our messaging feature allows for quick and easy communication between providers, patients, and their families.`,
  },
  {
    title: "Audio Calls",
    description: `Our platform offers high-quality audio calls, allowing for clear and effective communication between providers and patients.`,
  },
  {
    title: "Video Calls",
    description: `Our video call feature allows for face-to-face communication between providers and patients, even when they can't be in the same room.`,
  },
  {
    title: "Built-inFax",
    description: `Our built-in fax feature allows you to send and receive faxes directly within our platform, eliminating the need for separate fax machines and reducing the risk of errors.`,
  },
  {
    title: "Chat Groups",
    description: `Our chat groups feature allows you to create custom groups for specific patient cases or teams, making it easy to communicate and collaborate with the right people.`,
  },
  {
    title: "Geofencing",
    description: `Our geofencing feature allows you to set up virtual perimeters around specific locations, such as nursing homes or assisted living facilities, and receive alerts when a patient or provider enters or leaves that location.`,
  },
];

export default PrivacyPolicy;
