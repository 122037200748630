import React, { useRef } from 'react';
import styles from './AnimatedVideo.module.css';

const AnimatedVideo = () => {
  const videoRef = useRef(null);
  function handleVideoEnded() {
    videoRef.current.currentTime = 0.99;
    videoRef.current.pause();
  }
  return (
    <div className='wrapper-container section-margin'>
      {/* <h1 className='heading blue-text'></h1> */}
      <div className={styles.videoContainer}>
        <video
          ref={videoRef}
          className={styles.video}
          autoPlay={true}
          controls={true}
          // loop
          onEnded={handleVideoEnded}
          poster='/video/poster.png'
          src='/video/video.mp4'
          alt='doc-nock animated video'
        />
      </div>
    </div>
  );
};

export default AnimatedVideo;
