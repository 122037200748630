import { AiOutlineMail } from 'react-icons/ai';
import './Footer.css';

const Footer = () => {
  return (
    <>
      <div className='footer section-margin wrapper-container'>
        <div className='footer_container'>
          <img src={'/logo.png'} alt='logo' height={200} width={200} />
          <p className='text-center blue-text w-80 text-lg'>
            Please provide your name and phone number and we will get back to
            you.
          </p>
          <div className='footerTopRight'>
            <p className='Text blue-text'>Contact Us</p>

            <div className='Address'>
              <a
                href='mailto:MD@doc-nock.com'
                className='green-text-hover'
                style={{ display: 'flex', gap: '0.5rem' }}
              >
                <AiOutlineMail
                  style={{ verticalAlign: 'middle', fontSize: '1.7rem' }}
                />
                MD@doc-nock.com
              </a>
            </div>
          </div>
        </div>
        <div className='footerBottom'>
          <div className='footercheck'>
            <div className='footerBottomLeft'>
              <p className='green-text'>© 2023 Doc Nock</p>
            </div>
            <div className='footerRight'>
              <img src='./fb.png' alt='doc-nock social media' />
              <img src='./inst.png' alt='doc-nock social media' />
              <img src='./link.png' alt='doc-nock social media' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
